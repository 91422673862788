import './Footer.css';
import resume from '../../assets/resume.png';
import tcsnqt from '../../assets/tcsnql.png';

const Footer=()=>{
    return(
        <div className='footer'>
            <div className='detail'>
             <h3>kishlay455@gmail.com</h3>
                    <h3>9060050455</h3>
            </div>
                <div className='column'>
                   
                <div className='holder'>
                    <h3>Download My Resume</h3>
                    <a href='https://drive.google.com/file/d/1zDgGAUaTxfKiflwglOm_blmwIL1ZhAIB/view?usp=sharing'
                    target='_blank' rel="noreferrer">
                        <img className='image resume' src={resume} alt='resume'/>
                    </a>
                </div> 
                <div className='holder'> 
                <h3>Download My TCS NQT Result</h3>  
                    <a href='https://drive.google.com/file/d/1sievUvKtTs04h8htX-0TRmmW9dgS2y_1/view?usp=sharing'
                    target='_blank' rel="noreferrer">
                        <img className='image nqt' src={tcsnqt} alt='NQT RESULT'/>
                    </a>
                </div>    
                </div>
                <div className='copyright'>
                    <h5>Copyright &#169; 2023 Kishlay Kumar. All right reserved.</h5>
                </div>
            </div>

    )
};

export default Footer;