import './Portfolio.css';
import Project from '../card/Project';
import Card from '../card/Card';

const Portfolio=()=>{
  const datarrray=[
    { id:0,
      title:'Online Code Editor',
      technologiesUsed:"NodeJs ExpressJs ReactJs MongoDB JavaScript HTML CSS",
      description:"A end-to-end Full Stack Web Application which consists of code Editor and Compiler which can run and execute programs written in different programming languages",
      liveLink:'https://codekishlay.netlify.app/',
      github:"https://github.com/Kishlay22/IDE"

    },
    { id:1,
      title:'Chat Application',
      technologiesUsed:"React.js ASP.NET Web API SignalR",
      description:"Developed a Web-based chat application with SignalR integrated for real-time data updates where users can join specific chat rooms, engage in group conversations, and send direct messages to each other.",
      github:"https://github.com/Kishlay22/ChatApplication"

    }

  ]
  return(
    <>
    <h1 className='projectheading'>PROJECTS</h1>
    
   <div className='projectlist'>
    <ul>
      
        {datarrray.map((elements=>(
          <Card key={elements.title}>
          <Project keys={elements.title}
             id={elements.id}
             title={elements.title}
             description={elements.description}
             technologies={elements.technologiesUsed}
             github={elements.github}
             liveLink={elements.liveLink}
          ></Project>
          </Card>
        )))}
      
      
    
    </ul>
   </div>
   </>
  )
}

export default Portfolio;