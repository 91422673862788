import React,{useState} from "react";
import './Navbar.css';
import Menu from '../../assets/menu.png'
import logo from '../../assets/logo.png';
import {Link} from 'react-scroll';
const Navbar=()=>{
    const [showMenu,setShowMenu]=useState(false);
    function HideMenubar(){
        setShowMenu(false);
    }

    return(
<>
        <nav className="navbar">
       
        <img src={logo} alt="logo" className="logo"/>
        <div className="menu">
             <Link activeClass="active" to="intro" spy={true} smooth={true} offset={-100} duration={500} className="menuitem home">Home</Link>
             <Link activeClass="active" to="skill" spy={true} smooth={true} offset={-160} duration={500} className="menuitem skills">Skills</Link>
             <Link activeClass="active" to="projectheading" spy={true} smooth={true} offset={-100} duration={500} className="menuitem projects">Projects</Link>

        </div>
        <button className="menubtn" onClick={()=>{
            document.getElementById('contact').scrollIntoView({behavior:"smooth",block:"start"});
        }}>Contact Me</button>
        
          <img src={Menu} alt="Menu" className="mobmenu"
          onClick={()=>setShowMenu(!showMenu)}/>
          <div className="mobilemenubox" style={{display:showMenu?'flex':"none"}}>
                <Link activeClass="active" to="intro" spy={true} smooth={true} offset={-100} duration={500} className="mmobilemenu home" onClick={HideMenubar}>Home</Link>
                <Link activeClass="active" to="skill" spy={true} smooth={true} offset={-160} duration={500} className="mmobilemenu skills" onClick={HideMenubar}>Skills</Link>
                <Link activeClass="active" to="projectheading" spy={true} smooth={true} offset={-100} duration={500} className="mmobilemenu home" onClick={HideMenubar}>Project</Link>
                <Link activeClass="active" to="contactheading" spy={true} smooth={true} offset={-160} duration={500} className="mmobilemenu contactbtn" onClick={HideMenubar}>Contact</Link>
            
          </div>
        
     </nav>
     </>
    )
}
export default Navbar;