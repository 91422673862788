import './Skill.css';
import java from '../../assets/java.png';
import dsa from '../../assets/dsa.png';
import html from '../../assets/html.png';
import css from '../../assets/css.png';
import js from '../../assets/javascript.png';
import reactpic from '../../assets/react.png';
import node from '../../assets/node.png';
import csharp from '../../assets/c-sharp.jpeg';
import github from '../../assets/github.png';
import aspnet from '../../assets/aspnet.png';



const Skill=()=>{
    return(
       <div className='skill'>
         <h1 className='heading'>SKILLS</h1>
         <div className='skillitems'>
           <img className='skillitem' src={java} alt='java'/>
           <img className='skillitem' src={dsa} alt='dsa'/>
           <img className='skillitem' src={html} alt='html'/>
           <img className='skillitem' src={css} alt='css'/>
           <img className='skillitem' src={js} alt='js'/>
           <img className='skillitem' src={csharp} alt='C-Sharp'/>
           <img className='skillitem' src={reactpic} alt='react'/>
           <img className='skillitem' src={node} alt='node'/>
           <img className='skillitem' src={aspnet} alt='ASP.NET'/>
           <img className='skillitem' src={github} alt='GitHub'/>
        </div>
       </div> 
    )
}

export default Skill;
