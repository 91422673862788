import "./Contact.css";
import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
export const Contact = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_qqmknw2','template_m5muz0c', form.current,'qks9TMOWPNIFvZNiG')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      Thanks();
  };

  const [thank,setThank]=useState(false);
 const Thanks=()=>{
  setThank((prev)=>{
    return (!prev);
  })
 };

  return(
    <div className="contact" id="contact">
        <h1 className='contactheading'>CONTACT FORM</h1>
    {thank && <div className="thanks">
      <h2>Thank You for messaging me</h2>
      <button className="thanksbtn" onClick={Thanks}>Resend Message</button>
      </div>}    
    {!thank &&<form className="contactform" ref={form} onSubmit={sendEmail}>
      <h3>Do you have any message for me. Please write here</h3>
        <input type="text" className="input name"  placeholder="Enter your name" name="user_name" required/>
        <input type="email" className="input email" placeholder="Enter email ID" name="user_email" required/>
        <input type="number" className="input number" minLength={10} maxLength={10} placeholder='Enter mobile number' name='mobile' required/>
        <input type="text" className="input msg" name="message" placeholder="Enter message" />
        <button type="submit" className="submitbtn">Submit</button>
    </form>}
    </div>
  )

}

export default Contact;