import './Project.css'
const Project=(props)=>{
return(<li key={props.keys} id={props.id} className='projectcard'>
  <div className="projectitem">
    
    <div className='projectdetails'>
     <h2>{props.title}</h2>
     <h3>{props.description}</h3>  
     <h2>{props.technologies}</h2>
     </div>
     <div className='projectlinks'>
     <a className='git' target="_blank" href={props.github} rel="noreferrer"><span>Github Link</span></a>
    {props.liveLink&& <a className='live' target="_blank" href={props.liveLink} rel="noreferrer"><span>Live Link</span></a>}
     </div>
    </div>
     </li>
 

)
};
export default Project;