import './intro.css';
import myphoto from '../../assets/kk.png';
const Intro=()=>{
    return(
        <section className='intro'>
            <div className='mydata'>
                <h3>Hello,</h3>
                <h1>I'm <span>Kishlay</span></h1>
                <h1>Software Engineer</h1>
                <p>I am a final year BTech (Computer Science and Engineering) Student and looking for an entry
                    level job in Software field.
                </p>
            </div>
            <img src={myphoto} alt='myphoto'></img>

        </section>
    )
}
export default Intro;